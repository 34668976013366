enum DynamicFieldTypes {
  text = 'text',
  number = 'number',
  textarea = 'textarea',
  select = 'select',
  multiselect = 'multiselect',
  autocomplete = 'autocomplete',
  table = 'table',
  unit = 'unit',
}
export default DynamicFieldTypes;
