















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import { debounce } from 'lodash';
import UserSearchParams from '@improve/common-utils/src/types/UserSearchParams';
import User from '@improve/common-utils/src/model/User';
import Team from '@improve/common-utils/src/model/Team';
import Ticket from '@improve/common-utils/src/model/Ticket';
import BaseUserAvatar from '../widgets/BaseUserAvatar.vue';
import BaseTeamMemberCard from '../widgets/BaseTeamMemberCard.vue';
import BaseUserSearch from '../widgets/BaseUserSearch.vue';
import BaseButton from '../widgets/BaseButton.vue';
import BaseTextInput from '../core/BaseTextInput.vue';

@Component({
  name: 'BaseUserAssign',
  components: {
    BaseUserAvatar,
    BaseTeamMemberCard,
    BaseTextInput,
    BaseUserSearch,
    BaseButton
  }
})
export default class BaseUserAssign extends Vue {
  @Getter allTeamsById?: (id: string) => Team;

  @Getter currentUser?: User;

  @Prop({ default: false }) readonly show!: boolean;

  @Prop({ default: null }) readonly ticket!: Ticket;

  @Action fetchUsers!: ActionMethod;

  @Action updateTicket!: ActionMethod;

  private users: Array<User> | null = null;

  private search = '';

  private closeIcon = '';

  private searchInProgress = false;

  private debounceSearchInput = debounce((event: string) => {
    this.fetchUsersByName(event, false);
  }, 300);

  private cardHeight!: string;

  fetchUsersByName(searchName: string, searchCurrentUser: boolean): void {
    const search = new UserSearchParams();
    search.name = searchName;
    if (!this.ticket.assignee && searchCurrentUser) {
      search.unit = this.currentUser!.unit!;
    }
    this.searchInProgress = true;
    this.fetchUsers({ params: search }).then((users: Array<User>) => {
      this.users = users;
      this.searchInProgress = false;
      this.closeIcon = 'close';

      switch (this.users.length) {
        case 1: {
          this.cardHeight = '56px';
          break;
        }
        case 2: {
          this.cardHeight = '112px';
          break;
        }
        default: {
          this.cardHeight = '144px';
          break;
        }
      }

      if (!this.ticket.assignee && searchCurrentUser) {
        this.cardHeight = '56px';
        this.users = new Array<User>();
        this.users.push(this.currentUser!);
      }
    });
  }

  async created(): Promise<void> {
    this.cardHeight = '0px';
    if (!this.ticket.assignee) {
      this.fetchUsersByName(
        (this.currentUser!.firstName!
          .concat(' ')
          .concat(this.currentUser!.lastName!)
        ),
        true
      );
    }
  }

  clearSearch(): void {
    this.users = [];
    this.search = '';
    this.cardHeight = '0';
    this.closeIcon = '';
  }

  assignToUser(user: User): void {
    this.$emit('onAssignedToUser', user);
  }
}
