import Ticket from './Ticket';

const config = {
  taskType: process.env.VUE_APP_TASK_TYPE
};

export default class Task extends Ticket {
  constructor(id?: string) {
    super();
    this.id = id || undefined;
    this.type = config.taskType;
  }
}
