export enum BaseColumnAlignment {
  start, center, end
}

export default class BaseTableColumn {
  constructor(
    text? : string,
    value? : string
  ) {
    this.text = text || '';
    this.value = value || '';
    this.type = 'text';
    this.align = BaseColumnAlignment.start;
  }

  text: string;

  value: string;

  type: string;

  align: BaseColumnAlignment;

  isActionColumn?: boolean = false;

  sortable?: boolean = false;

  filterable?: boolean = false;

  groupable?: boolean = false;

  divider?: boolean = false;

  class?: string | null = null;

  _cellClass: string | null = null;

  _width: string | number | null = null;

  filter?: (value: any, search: string, item: any) => boolean;

  sort?: (a: any, b: any) => number;

  public get width(): string | number | null {
    return this.isActionColumn ? '5%' : this._width;
  }

  public set width(width: string | number | null) {
    this._width = width;
  }

  public get cellClass(): string {
    return this.isActionColumn ? 'custom-actions-cell' : this.value;
  }

  public set cellClass(className: string) {
    this._cellClass = className;
  }
}
