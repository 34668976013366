







































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { classToClass } from 'class-transformer';
import BaseTableColumn from '@improve/common-utils/src/types/BaseTableColumn';
import Team from '@improve/common-utils/src/model/Team';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import BaseMenu from '../widgets/BaseMenu.vue';
import BaseModal from '../widgets/BaseModal.vue';
import BaseButton from '../widgets/BaseButton.vue';
import BaseAddButton from '../widgets/BaseAddButton.vue';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseControlWrapper from './BaseControlWrapper.vue';
import DynamicFieldTypes from '../../types/DynamicFieldTypes';

@Component({
  name: 'BaseTable',
  components: {
    BaseModal,
    BaseButton,
    BaseControlWrapper,
    BaseMenu,
    BaseAddButton,
    // Because we want to use the dynamic field editor
    BaseDynamicField: () => import('../widgets/BaseDynamicField.vue')
  }
})
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default class BaseTable extends BaseFormControl {
  @Prop({ default: () => [] }) readonly columns!: Array<BaseTableColumn>;

  @Prop({ default: () => [] }) readonly rows!: Array<any>;

  @Prop({ default: true }) readonly canAdd!: boolean;

  @Prop({ default: true }) readonly canEdit!: boolean;

  @Prop({ default: true }) readonly canDelete!: boolean;

  @Getter allTeamsById!: (id: string) => Team;

  selectedItem: any;

  showEditDialog = false;

  showDeleteDialog = false;

  customRows: Array<any> = [];

  get rowOptions(): Array<MenuOption> {
    const options: Array<MenuOption> = [];
    if (this.canEdit) {
      options.push({
        title: this.$t('menu.edit').toString(),
        value: 'edit'
      });
    }
    if (this.canDelete) {
      options.push({
        title: this.$t('menu.delete').toString(),
        value: 'delete'
      });
    }
    return options;
  }

  get editableColumns(): Array<BaseTableColumn> {
    return this.columns.filter((c) => c.value.indexOf('actions') < 0);
  }

  created(): void {
    super.created();
    this.init();
  }

  @Watch('rows')
  init() {
    this.customRows = classToClass(this.rows);
  }

  attemptAddingNewItem() {
    this.selectedItem = {};
    this.showEditDialog = true;
  }

  attemptUpdate(item: any): void {
    this.selectedItem = classToClass(item);
    this.showEditDialog = true;
  }

  async save() {
    if (!(await this.validateEditors())) return;
    const item = this.selectedItem;
    if (!item.id) {
      this.customRows.push({ id: Date.now(), ...item });
    } else {
      this.customRows = this.customRows.map((r) => (r.id === item.id ? item : r));
    }
    this.$emit('input', this.customRows);
    this.reset();
  }

  attemptDelete(item: any): void {
    this.selectedItem = classToClass(item);
    this.showDeleteDialog = true;
  }

  deleteConfirm(): void {
    this.customRows = this.customRows.filter((r) => r.id !== this.selectedItem.id);
    this.$emit('input', this.customRows);
    this.reset();
  }

  setDynamicFieldValue(input: any, header: BaseTableColumn): void {
    this.selectedItem[header.value] = this.valueByColType(input, header);
  }

  getDisplayDataByColType(item: any, header: BaseTableColumn) {
    const primitive = item[header.value];
    switch (header.type) {
      case DynamicFieldTypes.unit: {
        return this.allTeamsById(primitive)?.name;
      }
      default: {
        return primitive;
      }
    }
  }

  valueByColType(item: any, header: BaseTableColumn) {
    switch (header.type) {
      case DynamicFieldTypes.unit: {
        return item.id;
      }
      default: {
        return item;
      }
    }
  }

  reset() {
    this.showEditDialog = false;
    this.showDeleteDialog = false;
    this.selectedItem = null;
  }

  onMenuOptionClick(option: MenuOption, item: any): void {
    switch (option.value) {
      case 'edit': {
        this.attemptUpdate(item);
        break;
      }
      case 'delete': {
        this.attemptDelete(item);
        break;
      }
      default: {
        break; /* Otherwise ESLint complains */
      }
    }
  }

  validateEditors(): Promise<any> {
    const component: any = this.$refs.dynamicFields;
    return component.validate();
  }
}
