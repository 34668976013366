












import Vue from 'vue';
import { ActionMethod } from 'vuex';
import { Action, Getter } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import { debounce } from 'lodash';
import {
  DynamicDSTypes,
  ExternalField,
  FieldOptions
} from '@improve/common-utils/src/model/DynamicField';
import Team from '@improve/common-utils/src/model/Team';
import BaseTableColumn from '@improve/common-utils/src/types/BaseTableColumn';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseTextInput from '../core/BaseTextInput.vue';
import BaseAutoComplete from '../core/BaseAutoComplete.vue';
import BaseTextArea from '../core/BaseTextArea.vue';
import BaseSelect from '../core/BaseSelect.vue';
import BaseTable from '../core/BaseTable.vue';
import DynamicFieldTypes from '../../types/DynamicFieldTypes';

@Component({
  name: 'BaseDynamicField',
  components: {
    BaseTextInput,
    BaseTextArea,
    BaseSelect,
    BaseFormControl,
    BaseTable,
    BaseAutoComplete
  }
})
export default class BaseDynamicField extends Vue {
  // Because ref and key are both Vue reserved keywords
  @Prop({ default: null }) readonly prop!: string;

  @Prop({ default: null }) readonly value!: any;

  @Prop({ default: DynamicFieldTypes.text }) readonly type!: DynamicFieldTypes;

  @Prop({ default: null }) readonly field!: string;

  @Prop({ default: null }) readonly label!: string;

  @Prop({ default: null }) readonly placeHolder!: string;

  @Prop({ default: false }) readonly required!: boolean;

  @Prop({ default: null }) readonly options?: FieldOptions;

  @Prop({ default: true }) readonly editMode!: boolean;

  @Getter organizationalUnits!: Array<Team>;

  @Action fetchDynamicData!: ActionMethod;

  commonProps: any = {};

  commonListeners: any = {};

  fieldTypes = DynamicFieldTypes;

  get componentType(): string {
    switch (this.type) {
      case this.fieldTypes.table:
        return 'table';
      case this.fieldTypes.textarea:
        return 'text-area';
      case this.fieldTypes.unit:
      case this.fieldTypes.select:
      case this.fieldTypes.multiselect:
        return 'select';
      case this.fieldTypes.autocomplete:
        return 'auto-complete';
      case this.fieldTypes.text:
      case this.fieldTypes.number:
      default:
        return 'text-input';
    }
  }

  async created(): Promise<void> {
    await this.buildProps();
  }

  async buildProps(): Promise<void> {
    this.commonProps = await this.buildCommonProps(this);
    this.commonListeners = await this.buildCustomListeners(this);
  }

  async buildCommonProps(field: any): Promise<any> {
    return {
      id: field.prop,
      ref: field.prop,
      type: field.type,
      placeholder: this.getDynamicFieldText(field.placeHolder),
      field: this.getDynamicFieldText(field.field),
      label: this.getDynamicFieldText(field.label),
      rules: this.getDynamicFieldRules(field),
      value: this.value,
      ...(await this.buildCustomProps(field))
    };
  }

  getDynamicFieldRules(field: any): string[] {
    const rules = [];
    if (field.type === this.fieldTypes.number && field.required) {
      rules.push('number_required');
    }
    if (field.required) {
      rules.push('required');
    }
    return rules;
  }

  async buildCustomProps(field: ExternalField): Promise<any> {
    switch (field.type) {
      case this.fieldTypes.table: {
        return {
          columns: this.buildColumns(field),
          rows: this.buildRows(),
          canEdit: this.editMode,
          canDelete: this.editMode,
          canAdd: this.editMode
        };
      }
      case this.fieldTypes.unit: {
        return {
          items: this.organizationalUnits,
          'item-text': 'name',
          'item-value': 'id'
        };
      }
      case this.fieldTypes.select: {
        const items = await this.getDataSourceForField(field);
        return {
          items,
          'item-text': 'label',
          'item-value': 'id'
        };
      }
      case this.fieldTypes.multiselect: {
        const items = await this.getDataSourceForField(field);
        return {
          items,
          multiple: true,
          'item-text': 'label',
          'item-value': 'id'
        };
      }
      case this.fieldTypes.autocomplete: {
        const items = await this.getDataSourceForField(field);
        return {
          items,
          multiple: true,
          'return-object': true,
          'item-text': 'label',
          'item-value': 'id'
        };
      }
      default: {
        return {};
      }
    }
  }

  async getDataSourceForField(field: ExternalField): Promise<any> {
    return field.options?.datasource?.type === DynamicDSTypes.STATIC
      ? Promise.resolve(field.options?.getDataSource() || [])
      : this.getDataForDynamicField(field);
  }

  getDataSourceIdField(field: ExternalField): string {
    return field.options?.datasource?.settings.get('idField') || 'id';
  }

  getDataSourceLabelField(field: ExternalField): string {
    return field.options?.datasource?.settings.get('labelField') || 'label';
  }

  buildCustomListeners(field: any): any {
    switch (field.type) {
      case this.fieldTypes.autocomplete: {
        return {
          searchUpdated: debounce((searchFor: string) => {
            this.getDataForDynamicField(field as ExternalField, searchFor);
          }, 300)
        };
      }
      default: {
        return {};
      }
    }
  }

  buildColumns(field: ExternalField): Array<BaseTableColumn> {
    if (!field.options?.columns) return [];
    const columns = field.options.columns.map((c) => {
      const newCol = new BaseTableColumn();
      newCol.text = this.$t('form.'.concat(this.getDynamicFieldText(c.label))
        .replaceAll(' ', '')
        .toLowerCase()).toString();
      newCol.value = c.key;
      newCol.type = c.name;
      return newCol;
    });
    if (this.editMode) {
      const actionCol = new BaseTableColumn('', 'actions');
      actionCol.isActionColumn = true;
      columns.push(actionCol);
    }
    return columns;
  }

  buildRows(): Array<any> {
    return this.value && Array.isArray(this.value) ? this.value : [];
  }

  async getDataForDynamicField(
    externalField: ExternalField,
    searchFor = ''
  ): Promise<Array<any>> {
    const lF = this.getDataSourceLabelField(externalField);
    const iF = this.getDataSourceIdField(externalField);
    if (externalField.options?.datasource?.type === DynamicDSTypes.INTERNAL_API) {
      return this.fetchDynamicData({
        url: externalField.options?.datasource?.settings.get('url'),
        searchFor
      })
        .then((data: any) => {
          const dataArray = Array.isArray(data) ? data : Object.values(data);
          this.commonProps.items = dataArray.map((d: any) => ({ id: d[iF], label: d[lF] }));
          return this.commonProps.items;
        });
    }
    return externalField.options?.getDataSource() || [];
  }

  getDynamicFieldText(text?: string, prefix = 'externalFields.'): string {
    if (!text) return '';
    const field = `${prefix}${text}`;
    const trans = this.$te(field);
    return trans ? this.$t(field).toString() : text;
  }
}
