







































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { ActionMethod } from 'vuex';
import { ExternalField, FieldGroup } from '@improve/common-utils/src/model/DynamicField';
import Ticket from '@improve/common-utils/src/model/Ticket';
import TicketMetaInfo from '@improve/common-utils/src/types/TicketMetaInfo';
import BaseDynamicField from './BaseDynamicField.vue';
import DynamicFieldTypes from '../../types/DynamicFieldTypes';

@Component({
  name: 'BaseExternalFields',
  components: {
    BaseDynamicField
  }
})
export default class BaseExternalFields extends Vue {
  @Prop({ default: null }) readonly ticket!: Ticket;

  @Prop({ default: false }) readonly editMode!: boolean;

  @Prop({ default: false }) readonly canRemoveSection!: boolean;

  @Prop({ default: () => [] }) readonly enabledFieldGroupIds!: Array<string>;

  @Getter externalFields!: Array<FieldGroup>;

  @Action fetchExternalFields!: ActionMethod;

  enabledExternalFields: Array<FieldGroup> = [];

  externalFieldsData: any = {};

  fieldTypes = DynamicFieldTypes;

  @Watch('enabledFieldGroupIds')
  async updateDynamicFields(): Promise<void> {
    if (!this.externalFields) await this.fetchExternalFields(this.ticket.type);
    this.enabledExternalFields = this.externalFields.filter(
      (fieldGroup) => this.enabledFieldGroupIds.includes(fieldGroup.groupId)
    );
    this.externalFieldsData = {
      ...this.readExternalFields(this.enabledExternalFields),
      ...this.externalFieldsData
    };
  }

  getDynamicFieldText(text?: string, prefix = 'externalFields.'): string {
    if (!text) return '';
    const field = `${prefix}${text}`;
    const trans = this.$te(field);
    return trans ? this.$t(field).toString() : text;
  }

  getDynamicValueByType(field: ExternalField): any {
    switch (field.type) {
      case this.fieldTypes.select: {
        return this.externalFieldsData[field.ref]?.label;
      }
      case this.fieldTypes.autocomplete:
      case this.fieldTypes.multiselect: {
        const dataArray: Array<{id: string, label: string}> = this.externalFieldsData[field.ref];
        return dataArray?.map((d) => d.label).join(', ');
      }
      default: {
        return this.externalFieldsData[field.ref];
      }
    }
  }

  checkValidRenderData(field: ExternalField): boolean {
    switch (field.type) {
      case this.fieldTypes.table: {
        return !!this.getDynamicValueByType(field)?.length;
      }
      default: {
        return this.getDynamicValueByType(field);
      }
    }
  }

  readExternalFields(groups: Array<FieldGroup>): any {
    const fieldData: any = {};
    groups.forEach((group) => {
      group.fields.forEach((field) => {
        const fieldRef = field.ref as keyof TicketMetaInfo;
        if (!this.ticket.meta[fieldRef]) {
          fieldData[fieldRef] = null;
        } else {
          try {
            fieldData[fieldRef] = JSON.parse(this.ticket.meta[fieldRef] as string);
          } catch (ex) {
            fieldData[fieldRef] = this.ticket.meta[fieldRef];
          }
        }
      });
    });
    return fieldData;
  }

  writeExternalFields(groups: Array<FieldGroup>): any {
    const fieldData: any = {};
    groups.forEach((group) => {
      group.fields.forEach((field) => {
        const fieldRef = field.ref as keyof TicketMetaInfo;
        fieldData[fieldRef] = typeof this.externalFieldsData[fieldRef] === 'string'
          ? this.externalFieldsData[fieldRef]
          : JSON.stringify(this.externalFieldsData[fieldRef]);
      });
    });
    return fieldData;
  }

  prepareExternalFields(): any {
    return this.writeExternalFields(this.enabledExternalFields);
  }

  onInput(ref: string, data: Record<string, any>[]): void {
    this.externalFieldsData[ref] = data;
    this.$emit('input', { ref, data });
  }
}
