






































































































import Vue from 'vue';
import { classToClass } from 'class-transformer';
import { Getter } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import User from '@improve/common-utils/src/model/User';
import Task from '@improve/common-utils/src/model/Task';
import InlineSvg from 'vue-inline-svg';
import TaskStatus from '@improve/common-utils/src/types/TaskStatus';
import BaseCheckbox from '../core/BaseCheckbox.vue';
import BaseDatePicker from '../core/BaseDatePicker.vue';
import BaseUserAssign from '../user/BaseUserAssign.vue';
import BaseUserAvatar from '../widgets/BaseUserAvatar.vue';
import calendar from '../../assets/calendar-icon.svg';

@Component({
  name: 'BaseTask',
  components: {
    BaseCheckbox,
    BaseUserAvatar,
    BaseUserAssign,
    BaseDatePicker,
    InlineSvg
  }
})
export default class BaseTask extends Vue {
  @Getter users!: Array<User>;

  @Prop({ default: null }) readonly task!: Task;

  @Prop({ default: false }) readonly editTaskStatus!: boolean;

  @Prop({ default: false }) readonly editTaskTitle!: boolean;

  @Prop({ default: false }) readonly editTaskDeadline!: boolean;

  @Prop({ default: false }) readonly editTaskAssignee!: boolean;

  @Prop({ default: false }) readonly helper!: boolean;

  title: string | null = '';

  deadLine: Date | null = null;

  assignee: string | null = null;

  taskToEdit!: Task;

  calendarIcon = calendar;

  showUserSelector = false;

  editTitle = false;

  readyToRemove = false;

  taskUpdated = false;

  get completed(): boolean {
    return this.taskToEdit && this.taskToEdit.status === TaskStatus.COMPLETED;
  }

  get expired(): boolean {
    return this.deadLine ? new Date(this.deadLine) < new Date() : false;
  }

  get assigneeAvatar(): string | null {
    return (this.users && this.users.find((u) => u.id === this.assignee)?.avatar) || null;
  }

  get titlePlaceholder(): string {
    if (!this.title?.length && !this.helper && this.taskUpdated) {
      return this.$t('page.tasks.removeTask').toString();
    }
    return this.$t('page.tasks.addTask').toString();
  }

  get displayDeadline(): string {
    if (!this.$date(this.deadLine!).diff(new Date(), 'day')) {
      return this.$t('dayjs.today').toString();
    }

    const nextYear = this.$date(this.deadLine!).year() > new Date().getFullYear();
    const format = nextYear ? 'D MMM YYYY' : 'D MMM ';
    return this.$date(this.deadLine!).format(format);
  }

  get calendarDeadLine(): string | null {
    return this.deadLine ? this.$date(this.deadLine).format('YYYY-MM-DD') : null;
  }

  created(): void {
    this.taskToEdit = classToClass(this.task);
    this.init();
  }

  init(): void {
    this.title = this.taskToEdit.title || null;
    this.deadLine = this.taskToEdit.deadLine
      ? this.$date(this.taskToEdit.deadLine, 'YYYY-MM-DD').endOf('day').toDate()
      : null;
    this.assignee = this.taskToEdit.assignee || null;
    this.taskUpdated = false;
  }

  enableTitleEditor(): void {
    this.editTitle = true;
    this.focusOnEditor();
  }

  titleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Backspace' && this.readyToRemove) {
      this.$emit('taskDeleted', { task: this.taskToEdit });
      return;
    }
    if (event.key === 'Enter') {
      this.notifyUpdate();
    }
  }

  titleBlur(): void {
    this.editTitle = false;
    // Enable line below to report update in title blur
    // this.notifyUpdate();
  }

  changeTitle(newTitle: string): void {
    this.title = newTitle;
    this.taskUpdated = true;
    this.readyToRemove = !newTitle.length;
  }

  changeDeadline(date: string): void {
    this.deadLine = this.$date(date, 'YYYY-MM-DD').endOf('day').toDate();
    this.taskUpdated = true;
    this.notifyUpdate();
  }

  changeAssignee(user: User): void {
    this.assignee = user.id!;
    this.showUserSelector = false;
    this.taskUpdated = true;
    this.$forceUpdate();
    this.notifyUpdate();
  }

  notifyUpdate(): void {
    if (!this.taskUpdated || !this.title?.trim().length) return;
    this.taskToEdit.title = this.title!;
    this.taskToEdit.name = this.title!;
    this.taskToEdit.deadLine = this.deadLine!;
    this.taskToEdit.assignee = this.assignee!;
    const event = this.helper ? 'taskAdded' : 'taskUpdated';
    this.$emit(event, { task: this.taskToEdit });
    this.taskUpdated = false;
  }

  focusOnEditor(): void {
    const elName = `taskTitleEditor-${this.taskToEdit.id}`;
    const editor: any = this.$refs && this.$refs[elName];
    if (editor) {
      this.editTitle = true;
      editor.focus();
    }
  }

  onStatusToggle(value: boolean): void {
    this.$emit('taskCompleted', { task: this.taskToEdit, complete: value });
  }
}
